/** @jsx jsx */
import * as React from "react"
import { Fragment } from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import ReactJWPlayer from "react-jw-player"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Button from "../../components/button"
import tw, { styled } from "twin.macro"
import { jsx, css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faReact } from "@fortawesome/free-brands-svg-icons"
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import { Formik, useFormik } from "formik"
import { PieChart } from "react-minimal-pie-chart"
import PromoBlocks from "../../components/standardPromoBlock"
import IndexHero from "../../components/indexHero"
import IndexSwiper from "../../components/indexSwiper"

import * as Yup from "yup"
import ReactPlayer from "react-player"
import IndexTestimonialSwiper from "../../components/indexTestimonialSwiper"
import IndexSolutions from "../../components/indexSolutions"
import IndexProducts from "../../components/indexProducts"
import IndexCaseStudies from "../../components/indexCaseStudies"
import IndexBlogs from "../../components/indexBlogs"
import IndexLeadership from "../../components/indexLeadership"
import IndexWhitepaper from "../../components/indexWhitepaper"
import NewEvalForm from "../../components/newEvalForm"
import IndexLogos from "../../components/indexLogos"
import IndexIndustryRecognition from "../../components/indexIndustryRecognition"
import IndexInvestors from "../../components/indexInvestors"
import IndexFreeTrial from "../../components/indexFreeTrial"

const customArchitectureStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .75)",
    zIndex: "999",
  },
  content: {
    background: "none",
    // backgroundColor: '#000',
    padding: "0",
    // marginTop: "20vh",
    marginLeft: "auto",
    marginRight: "auto",
    border: "0",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
}

Modal.setAppElement("body")

const ArchitectureImage = () => {
  return (
    <div className={"flex items-center justify-center border border-white rounded-5xl bg-white h-[90vh] w-[90vw]"}>
      <StaticImage
        src={"../../images/streamingReferenceArchitectureBig.png"}
        className={""}
        imgClassName={"w-full"}
        quality={95}
        objectFit={"contain"}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt={"Streaming refererence architecture"}
      />
    </div>
  )
}

function ZycadaArchitectureModal() {
  let subtitle
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#FFF"
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className={"p-6 hover:border hover:border-zycadaDarkPurple border border-white rounded-5xl"}>
      <a onClick={openModal}>
        <StaticImage
          src="../../images/streamingReferenceArchitecture.png"
          className={""}
          imgClassName={""}
          quality={95}
          objectFit={"contain"}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Streaming refererence architecture"
          styles={{}}
          loading={"eager"}
          placeholder={"none"}
        />
      </a>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={customArchitectureStyles}
        contentLabel="Zycada video modal"
        overlayClassName={""}
      >
        <h2 ref={_subtitle => (subtitle = _subtitle)}></h2>
        <ArchitectureImage></ArchitectureImage>
      </Modal>
    </div>
  )
}

const SolutionsStreaming = () => (
  <Layout>
    <SEO title="Zycada streaming solutions reduce VST, re-buffer ratio and hand-waving latency." />

    <div className="">
      <div className={"pt-24"}>
        <div className="row pt-24 ">
          <div className="">
            <div className="container flex flex-col justify-center">
              <div className="row w-full">
                <div className=" text-center sm:text-left">
                  <h1 className=" display-4 fs-5 text-zycadaDarkPurple ">
                    Zycada for Streaming
                  </h1>

                  <div className={" sm:text-left"}>
                    <p className={"text-base"}>
                      Video is clearly the undisputed king when it comes to
                      dominating the Internet traffic. Video has shown that it
                      boosts end user engagement across all verticals including
                      eCommerce, gaming and more. The top OTT content providers
                      fight fiercely to retain customers through focusing on
                      four KPIs that drive their business success:
                    </p>

                    <ol className={"pt-2 mx-4 list-decimal"}>
                      <li className={""}>
                        <strong>Video Start Time (VST) </strong> - How fast the
                        video starts playing after a user clicks on the video
                      </li>
                      <li className={""}>
                        <strong>Rebuffering ratio</strong> - How often the video
                        runs out of buffer at the client end, causing stuttering
                        that interrupts playback
                      </li>
                      <li className={""}>
                        <strong>Average Bit Rate</strong> - What techniques the
                        underlying infrastructure uses to ensure the best
                        possible bitrate on the client's device
                      </li>
                      <li className={""}>
                        <strong>Hand-waving Latency</strong> - The time between
                        the video was first captured by the encoder to the time
                        when it was played on the client's screen.
                      </li>
                    </ol>

                    <p className={"text-base"}>
                      <strong>Video Start Time (VST) </strong> - Zycada uses its
                      Cloud Service Accelerator powered by patented RUBI (Real
                      User Behavioral Intelligence) technology to understand how
                      real users interact with the site and uses that
                      information to transmux content in real-time to boost VST.
                    </p>

                    <p className={"text-base"}>
                      <strong>Average Bit Rate & Rebuffering Ratio </strong> -
                      Traditionally the industry combats rebuffering reactively
                      - implying when the player detects slowness, it will
                      switch down the bit rate (reducing the average bit rate)
                      to refill the buffer aggressively to keep rebuffering
                      ratio low. But doing so will compromise the video quality.
                      Zycada's programmable intelligent edge platform use
                      Wireless Mile Acceleration and Real-time Video Transmuxer
                      to ensure that the player receives the maximum possible
                      bit rate and the user gets the best video experience.
                    </p>

                    <p className={"text-base"}>
                      <strong>Hand-waving Latency </strong> - While
                      theoretically the video delivered online can beat the
                      hand-waving latency of content delivered via satellite,
                      for practical reasons media players inflate hand-waving
                      latency to ensure other KPIs such as rebuffing ratio and
                      VST are low. Zycada's edge platform is the only solution
                      that reduces hand waving latency while keeping VST and
                      rebuffering ratio low.
                    </p>
                  </div>
                </div>
              </div>
              {/*<div className="row md:w-1/2">*/}
              {/*  <div className="text-center align-items-center">*/}
              {/*    <div className="text-center align-items-center">*/}
              {/*      <ZycadaModal></ZycadaModal>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="pt-6 text-center align-items-center"></div>*/}
              {/*</div>*/}
              <div className="pt-4 pb-4 text-center">
                <div className={"p-2 mx-auto"}>
                  <ZycadaArchitectureModal></ZycadaArchitectureModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <IndexFreeTrial />

    {/*<p>*/}
    {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
    {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
    {/*</p>*/}
  </Layout>
)

export default SolutionsStreaming
